import React, { useCallback, Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import isNil from "lodash/isNil";

import Dialog from "@ui-kit/Dialog";
import Typography from "@ui-kit/Typography";
import { Spacer } from "@ui-kit/Spacer";
import Button from "@ui-kit/Button";

import { isAdmin } from "@selectors";
import { useUserStore } from "@store/UserStore";
import { BoolItem } from "@pages/DeepDivePanel/components/BoolItem";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";
import { useCss } from "./styles";
import { Models } from "@services/api";
import { montessoriMethodsMap } from "@constants/montessori-method";

const hasPrevPhilosophy = (
  info: Models.FacilityServicesInfo,
  ph: keyof Models.FacilityServicesInfo,
) => {
  const all: (keyof Models.FacilityServicesInfo)[] = [
    "montessoriMethod",
    "regioEmiliaMethod",
    "waldorfMethod",
    "projectBasedLearning",
    "playBasedLearning",
  ];

  const idx = all.findIndex((p) => p === ph);
  const sliced = all.slice(0, idx);
  return sliced.some((p) => {
    return !!info[p];
  });
};

const ProgramsAndServices: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [{ facility }] = useDeepDivePanelStore();
  const [{ user }] = useUserStore();
  const css = useCss();

  const closeDialog = useCallback(() => {
    history.replace({
      pathname: `/map/${facility.id}`,
      search,
    });
  }, [history, search]);

  const hasPhilosophy =
    !isNil(facility.servicesInfo?.montessoriMethod) ||
    !!facility.servicesInfo.regioEmiliaMethod ||
    !!facility.servicesInfo.waldorfMethod ||
    !!facility.servicesInfo.playBasedLearning ||
    !!facility.servicesInfo.projectBasedLearning;

  return (
    <Dialog
      open
      onClose={closeDialog}
      data-test="programs-and-services-dialog"
      tier={facility.subscriptionTier}
      title="Programs & services"
    >
      <div css={css.root}>
        {hasPhilosophy && (
          <Fragment>
            <Typography variant="h5" align="center" css={css.sectionTitle}>
              Education philosophy
            </Typography>
            <Spacer size="small" />
          </Fragment>
        )}

        {!isNil(facility.servicesInfo?.montessoriMethod) && (
          <Fragment>
            <Typography gutterBottom>
              <Typography bolded variant="inherit">
                Montessori method:
              </Typography>{" "}
              <Typography bolded variant="inherit" color="success">
                Y
              </Typography>
              {facility.servicesInfo?.montessoriMethod !==
                Models.MontessoriMethod.Yes &&
                ` (${
                  montessoriMethodsMap[facility.servicesInfo?.montessoriMethod]
                    .name
                })`}
            </Typography>
            <Typography gutterBottom>
              <a
                href="https://youtu.be/Ljuw3grZ11Q"
                className="g-link"
                target="_blank"
                rel="noreferrer"
              >
                What is Montessori?
              </a>
            </Typography>
            <Typography italic>
              Montessori curriculum includes:
              <br />
              1. Montessori trained teachers
              <br />
              2. Montessori materials
              <br />
              3. multi-age classrooms
              <br />
              4. child-directed work
              <br />
              5. uninterrupted work period
            </Typography>
          </Fragment>
        )}

        {!!facility.servicesInfo?.regioEmiliaMethod && (
          <Fragment>
            {hasPrevPhilosophy(facility.servicesInfo, "regioEmiliaMethod") && (
              <Spacer size="medium" />
            )}
            <BoolItem
              label="Reggio Emilia method"
              state={facility.servicesInfo?.regioEmiliaMethod}
            />
            <Typography gutterBottom>
              <a
                href="https://www.youtube.com/watch?v=fYx_aGs-DjU"
                className="g-link"
                target="_blank"
                rel="noreferrer"
              >
                What is Reggio Emilia?
              </a>
            </Typography>
            <Typography italic>
              Reggio Emilia philosophy emphasizes learning that is child
              directed and project-based, with a focus on preschool and primary
              education.
            </Typography>
          </Fragment>
        )}

        {!!facility.servicesInfo?.waldorfMethod && (
          <Fragment>
            {hasPrevPhilosophy(facility.servicesInfo, "waldorfMethod") && (
              <Spacer size="medium" />
            )}
            <BoolItem
              label="Waldorf method"
              state={facility.servicesInfo?.waldorfMethod}
            />
            <Typography italic>
              In Waldorf schools days are typically filled with imaginary play,
              art, and music, with traditional academic subjects such as
              reading, writing and math introduced at around age 7 or so.
            </Typography>
          </Fragment>
        )}

        {!!facility.servicesInfo?.projectBasedLearning && (
          <Fragment>
            {hasPrevPhilosophy(
              facility.servicesInfo,
              "projectBasedLearning",
            ) && <Spacer size="medium" />}
            <BoolItem
              label="Project-based learning"
              state={facility.servicesInfo?.projectBasedLearning}
            />
            <Typography italic>
              A learn-by-doing approach, whereby students are encouraged to take
              on and complete real-world tasks and projects
            </Typography>
          </Fragment>
        )}

        {!!facility.servicesInfo?.playBasedLearning && (
          <Fragment>
            {hasPrevPhilosophy(facility.servicesInfo, "playBasedLearning") && (
              <Spacer size="medium" />
            )}
            <BoolItem
              label="Play-based learning"
              state={facility.servicesInfo?.playBasedLearning}
            />
            <Typography italic>
              Children are encouraged to actively explore and connect with the
              world around them
            </Typography>
          </Fragment>
        )}

        {hasPhilosophy && (
          <Fragment>
            <Spacer size="medium" />

            <Typography variant="h5" align="center" css={css.sectionTitle}>
              Programs & services
            </Typography>
            <Spacer size="small" />
          </Fragment>
        )}

        <BoolItem
          label="Discovery day (new students)"
          state={facility.servicesInfo?.enrollmentTrial}
        />
        {!!facility.servicesInfo?.enrollmentTrialDescription && (
          <Typography italic>
            {facility.servicesInfo?.enrollmentTrialDescription}
          </Typography>
        )}
        <Spacer size="medium" />

        <BoolItem label="Flex care" state={facility.servicesInfo?.flexCare} />
        {!!facility.servicesInfo?.flexCareDescription && (
          <Typography italic>
            {facility.servicesInfo?.flexCareDescription}
          </Typography>
        )}
        <Spacer size="medium" />

        <BoolItem
          label="Back-up care"
          state={facility.servicesInfo?.backupCare}
        />

        {!!facility.servicesInfo?.backupCareDescription && (
          <Typography italic>
            {facility.servicesInfo?.backupCareDescription}
          </Typography>
        )}
        <Spacer size="medium" />

        <BoolItem
          label="Weekend service"
          state={facility.servicesInfo?.weekendService}
        />
        {!!facility.servicesInfo?.weekendServiceDescription && (
          <Typography italic>
            {facility.servicesInfo?.weekendServiceDescription}
          </Typography>
        )}
        <Spacer size="medium" />

        <BoolItem
          label="After-school program"
          state={facility.servicesInfo?.afterSchoolProgram}
        />
        {!!facility.servicesInfo?.afterSchoolProgramDescription && (
          <Typography italic data-test="afterSchoolProgramDescription-display">
            {facility.servicesInfo?.afterSchoolProgramDescription}
          </Typography>
        )}
        <Spacer size="medium" />

        <BoolItem
          label="Summer camp"
          state={facility.servicesInfo?.summerCamp}
        />

        {!!facility.servicesInfo?.summerCampDescription && (
          <Typography italic data-test="summerCampDescription-display">
            {facility.servicesInfo?.summerCampDescription}
          </Typography>
        )}
        <Spacer size="medium" />

        <BoolItem
          label="Enrichment classes"
          state={facility.servicesInfo?.enrichmentClasses}
        />
        {!!facility.servicesInfo?.enrichmentClassesDescription && (
          <Typography italic data-test="enrichmentClassesDescription-display">
            {facility.servicesInfo?.enrichmentClassesDescription}
          </Typography>
        )}

        {(facility.isEditable || isAdmin(user)) && (
          <>
            <Spacer size="md" />
            <Button
              color={
                facility.subscriptionTier === Models.SubscriptionTier.Pro
                  ? "black"
                  : "primary"
              }
              variant="outlined"
              fullWidth
              size="large"
              link={`/map/${facility.id}/edit/programs-and-services`}
            >
              Edit programs & services
            </Button>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default ProgramsAndServices;
